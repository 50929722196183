import styled from '@emotion/styled';
import { useState } from 'react';

const DefaultLanguage = styled.div`
    display: flex;
    align-items: center;
`;

const LanguageChanger = styled.div<{
    margin?: string;
    background?: string;
}>`
    display: flex;
    padding: 8px;
    align-items: center;
    flex-direction: column;
    min-width: 60px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme, color }) => color || theme.colors.purple1};
    cursor: pointer;
    justify-self: flex-start;
    ${props => props.margin && `margin: ${props.margin}`};
`;

export default function LanguagePicker({ background, color, margin, ...props }: any) {
    const initialLang =
        typeof document !== 'undefined' && document.cookie.includes('googtrans=/en/es')
            ? 'ESP'
            : 'ENG';

    const [currentLang, setCurrentLang] = useState(initialLang);
    const handleLanguageChange = (lang: string) => {
        setCurrentLang(lang);
        document.cookie = `googtrans=;domain=.${window.location.hostname};expires=${new Date(
            0
        ).toUTCString()}`;
        document.cookie = `googtrans=/en/${lang.toLowerCase().slice(0, 2)};`;
        window.location.reload();
    };

    return (
        <LanguageChanger background={background} color={color} margin={margin} {...props}>
            <DefaultLanguage
                onClick={() => handleLanguageChange(currentLang === 'ENG' ? 'ESP' : 'ENG')}
            >
                <span className="notranslate">{currentLang === 'ENG' ? 'Espanol' : 'English'}</span>
            </DefaultLanguage>
        </LanguageChanger>
    );
}
