import { PlainBtn } from '@/Buttons/PlainBtn';
import { SecondaryBtn } from '@/Buttons/SecondaryBtn';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import '@reach/menu-button/styles.css';
import useClickAway from '@s/hooks/useClickAway';
import useRouteChange from '@s/hooks/useRouteChange';
import useScrollBlock from '@s/hooks/useScrollBlock';
import FocusTrap from 'focus-trap-react';
import { useRef, useState } from 'react';
import close from '../../../assets/images/icons/close.svg';
import menu from '../../../assets/images/icons/menu.svg';
import LanguagePicker from './LanguagePicker';
import { MobSubNav } from './MobSubNav';
import { NavLink } from './Nav';
import { navLinks } from './NavLinks';
import { flexStart } from './styles/classes';

const MenuBtn = styled(PlainBtn)`
    :hover,
    :focus {
        opacity: 0.5;
    }
`;

const CloseBtn = styled(PlainBtn)`
    align-self: flex-end;
    margin-bottom: 32px;

    :hover,
    :focus {
        opacity: 0.5;
    }

    @media (max-height: 800px) {
        margin-bottom: 24px;
    }
`;

const StyledNav = styled.div<{ navOpen: boolean; navVisibility: boolean }>`
    position: fixed;
    z-index: 100;
    top: 64px;
    height: calc(100% - 64px);
    max-height: 1000px;
    right: 0;
    width: 100vw;
    max-width: 420px;
    transform: ${({ navOpen }) => (navOpen ? 'translate(0)' : 'translateX(110vw)')};
    transition: transform 0.3s ease-in-out;
    background: ${({ theme }) => theme.colors.purple5};
    padding: 96px 16px 100px;
    border-radius: 20px 0px 0px 20px;
    visibility: ${({ navVisibility }) => (navVisibility ? 'visible' : 'hidden')};

    &,
    nav {
        ${flexStart};
        flex-direction: column;
    }

    @media (max-height: 800px) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    .language-picker-mobile {
        color: #fff;
    }
`;

export const MobNav = ({ category }: { category: string }) => {
    const [navOpen, setNavOpen] = useState(false);
    const [navVisibility, setNavVisibility] = useState(false);

    const closeNav = () => {
        setNavOpen(false);
        setTimeout(() => setNavVisibility(false), 300);
    };

    const toggleNav = () => {
        if (navOpen) {
            closeNav();
        } else {
            setNavVisibility(true);
            setTimeout(() => setNavOpen(true), 10);
        }
    };

    const navRef = useRef(null);
    const FocusRef = useRef(null);

    useScrollBlock(navOpen);

    useClickAway(navRef, closeNav);

    useRouteChange(setNavOpen);

    return (
        <div
            ref={navRef}
            css={css`
                height: 17px;

                @media (min-width: 1080px) {
                    display: none;
                }
            `}
        >
            <MenuBtn onClick={toggleNav}>
                <img src={menu} alt="open nav menu" width={24} height={17} />
            </MenuBtn>
            <FocusTrap
                active={navOpen}
                focusTrapOptions={{
                    initialFocus: false,
                    escapeDeactivates: false,
                    allowOutsideClick: true,
                    clickOutsideDeactivates: true,
                }}
                ref={FocusRef}
            >
                <StyledNav navOpen={navOpen} navVisibility={navVisibility}>
                    <CloseBtn onClick={closeNav}>
                        <img src={close} alt="close nav menu" width={24} height={17} />
                    </CloseBtn>
                    <nav>
                        {navLinks.map((link, i) =>
                            link.link === 'submenu' ? (
                                <MobSubNav
                                    text={link.text}
                                    key={i}
                                    closeNav={closeNav}
                                    category={category}
                                    navId={i}
                                />
                            ) : (
                                <NavLink to={link.link} activeClassName="nav-link-current-page">
                                    {link.text}
                                </NavLink>
                            )
                        )}
                    </nav>
                    <SecondaryBtn
                        as="a"
                        href="#appointment"
                        onClick={closeNav}
                        css={css`
                            margin-top: auto;
                        `}
                    >
                        Request an Appointment
                    </SecondaryBtn>
                    <br />
                    <LanguagePicker className="language-picker-mobile" />
                </StyledNav>
            </FocusTrap>
        </div>
    );
};
