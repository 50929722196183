import { css, Global, Theme } from '@emotion/react';
import '@fontsource/domine';
import '@fontsource/poppins';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import emotionNormalize from 'emotion-normalize';
import { h1, h2, h3, h4 } from './Typography';

export const theme: Theme = {
    colors: {
        white: '#FFFFFF',
        yellow1: '#FFF496',
        orange1: '#84642B',
        orange2: '#FFCE3E',
        orange3: '#FFE9AD',
        orange4: '#FEF3E3',
        purple1: '#8525FF',
        purple2: '#53399E',
        purple3: '#CBB9FF',
        purple4: '#254BCD',
        purple5: '#333958',
        gray1: '#ffffff',
        gray2: '#C2CAD0',
        gray3: '#859099',
        gray4: '#7A8185',
        gray5: '#232324',
    },
    spacing: {
        lg: '4.45vw', //64px at 1440px wide viewport
        xl: '6.67vw', //96px ^^
        xxl: '8.89vw', //128px ^^
    },
    sizes: {
        md: '520px',
        lg: '750px',
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={theme => css`
                ${emotionNormalize};

                html {
                    font-size: 100%;
                    overflow-x: hidden;
                    scroll-behavior: smooth;
                }
                body {
                    color: ${theme.colors.gray5};
                    font-family: 'Poppins', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 169.68%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    word-wrap: break-word;
                    -webkit-font-smoothing: antialiased;
                    top: 0 !important;
                }

                h1 {
                    ${h1};
                }

                h2 {
                    ${h2};
                }

                h3 {
                    ${h3};
                    color: ${theme.colors.purple2};
                }

                h4 {
                    ${h4};
                }

                a {
                    text-decoration: none;
                    color: ${theme.colors.gray5};
                    outline: none;
                    background-color: transparent;
                    -webkit-text-decoration-skip: objects;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                    margin: 0;
                    padding: 0;
                }

                ul,
                ol {
                    padding-inline-start: 24px;
                }

                .skiptranslate {
                    display: none;
                }
            `}
        />
    );
};
